<template>
  <mobile-screen :header="true" screen-class="profile-screen">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="applications-header">
        <template v-slot:left>
          <router-link :to="{ name: 'r_translations-select-page' }">
            <svg-icon icon="arrow-left"></svg-icon>
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("translations", "page", "title") }}
        </div>
        <template v-slot:right>
          <button @click="setEditingStatus(true)" v-if="!editing">
            <icon icon="#cx-hea1-edit" />
          </button>
          <button @click="setEditingStatus(false)" v-if="editing">
            <icon icon="#cx-hea1-edit" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul
      class="profile-data-list"
      v-if="!editing && selectedPage && selectedPage.length"
    >
      <li class="label">
        Translations
      </li>
      <li class="info">
        <dl class="profile-data-definition-list">
          <dt class="label">
            <span>{{ selectedPage[0].page }}</span>
          </dt>
          <dd class="data">
            <span>{{ selectedPage[0].name }}</span>
          </dd>
        </dl>
      </li>
    </ul>
    <VeeForm
      v-else
      @submit="submitForm"
      ref="editPageForm"
      v-slot="{ errors }"
      class="form tiny-input edit-form"
      :initial-values="this.selectedPage && this.selectedPage[0]"
      novalidate
    >
      <div class="form-group input">
        <label class="tiny-label">
          {{ displayLabelName("translations", "page", "name") }}
        </label>
        <Field
          name="name"
          as="input"
          type="text"
          rules="required"
          :disabled="submitting"
        />
        <span class="error-message" v-if="errors.name">
          {{ errors.name }}
        </span>
      </div>
      <div class="form-group submit">
        <button class="btn rounded light" type="submit" :disabled="submitting">
          {{ displayLabelName("translations", "page", "save-changes") }}
        </button>
      </div>
    </VeeForm>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import translationsMixin from "@/services/mixins/translations/translations-mixin";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "Page",
  data() {
    return {
      editing: false,
      submitting: false
    };
  },
  computed: {
    ...mapState("translation", ["selectedPage"])
  },
  mounted() {
    if (!this.selectedPage) {
      this.$router.push({ name: "r_translations-select-page" });
    }
  },
  methods: {
    ...mapActions("translation", ["getPages", "setSelectedPage"]),
    setEditingStatus(status) {
      this.editing = status;
    },
    submitForm(values, { resetForm }) {
      if (values) {
        const data = {
          ...this.selectedPage[0],
          ...values
        };

        this.submitting = true;
        this.submitMasterData(data, resetForm);
      }
    },
    submitMasterData(values, resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.master.pages}/${this.selectedPage[0].id}`,
          values,
          {
            params: {
              lang: this.selectedLanguage.locale
            }
          }
        )
        .then(() => {
          resetForm();
          this.editing = false;
          this.getPages({
            sectionId: this.selectedPage[0].id,
            lang: this.selectedLanguage.locale
          });
          this.setSelectedPage([
            {
              ...values
            }
          ]);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [translationsMixin]
};
</script>
